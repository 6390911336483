var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-xs",staticStyle:{"display":"flex","flex-direction":"column","height":"100%"}},[_c('AppHeader',{attrs:{"back-to":"/profile/edit"}}),_c('div',{staticStyle:{"display":"grid"}},[_c('x-label',{staticStyle:{"margin-top":"2rem"},attrs:{"for":"password-1","tag":"label"}},[_vm._v(_vm._s(_vm.$t('account.current-password')))]),_c('form-input',{attrs:{"id":"password-1","type":"password","validator":value => (value ? true : _vm.$t('form.password-required')),"required":""},on:{"valid":_vm.forms.vset.profile.pass},model:{value:(_vm.forms.profile.pass),callback:function ($$v) {_vm.$set(_vm.forms.profile, "pass", $$v)},expression:"forms.profile.pass"}})],1),_c('div',{staticClass:"grid"},[_c('x-label',{staticStyle:{"margin-top":"2rem"},attrs:{"for":"new-password","tag":"label"}},[_vm._v(_vm._s(_vm.$t('account.new-password')))]),_c('form-input',{attrs:{"id":"new-password","type":"password","validator":value =>
          !value
            ? _vm.$t('form.password-required')
            : value === _vm.forms.profile.pass
            ? _vm.$t('form.password-same-as-before')
            : value.length < 8
            ? _vm.$t('form.password-too-short')
            : true,"required":"","minlength":"8"},on:{"valid":_vm.forms.vset.profile.newPass},model:{value:(_vm.forms.profile.newPass),callback:function ($$v) {_vm.$set(_vm.forms.profile, "newPass", $$v)},expression:"forms.profile.newPass"}})],1),_c('div',{staticClass:"grid"},[_c('x-label',{staticStyle:{"margin-top":"2rem"},attrs:{"for":"new-password-2","tag":"label"}},[_vm._v(_vm._s(_vm.$t('account.confirm-new-password')))]),_c('form-input',{attrs:{"id":"new-password-2","type":"password","validator":value =>
          !value
            ? _vm.$t('form.password-required')
            : value !== _vm.forms.profile.newPass
            ? _vm.$t('form.password-doesnt-match')
            : true,"required":""},on:{"valid":_vm.forms.vset.profile.newPassConfirm},model:{value:(_vm.forms.profile.newPassConfirm),callback:function ($$v) {_vm.$set(_vm.forms.profile, "newPassConfirm", $$v)},expression:"forms.profile.newPassConfirm"}})],1),_c('x-button',{staticStyle:{"margin-top":"auto"},attrs:{"id":"save","type":"large","disabled":!_vm.forms.vget.profile.form,"is-loading":_vm.isLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }