<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/profile/edit" />
    <div style="display: grid">
      <x-label for="password-1" tag="label" style="margin-top: 2rem">{{
        $t('account.current-password')
      }}</x-label>
      <form-input
        id="password-1"
        v-model="forms.profile.pass"
        type="password"
        :validator="value => (value ? true : $t('form.password-required'))"
        required
        @valid="forms.vset.profile.pass"
      />
    </div>
    <div class="grid">
      <x-label for="new-password" tag="label" style="margin-top: 2rem">{{
        $t('account.new-password')
      }}</x-label>
      <form-input
        id="new-password"
        v-model="forms.profile.newPass"
        type="password"
        :validator="
          value =>
            !value
              ? $t('form.password-required')
              : value === forms.profile.pass
              ? $t('form.password-same-as-before')
              : value.length < 8
              ? $t('form.password-too-short')
              : true
        "
        required
        minlength="8"
        @valid="forms.vset.profile.newPass"
      />
    </div>
    <div class="grid">
      <x-label for="new-password-2" tag="label" style="margin-top: 2rem">{{
        $t('account.confirm-new-password')
      }}</x-label>
      <form-input
        id="new-password-2"
        v-model="forms.profile.newPassConfirm"
        type="password"
        :validator="
          value =>
            !value
              ? $t('form.password-required')
              : value !== forms.profile.newPass
              ? $t('form.password-doesnt-match')
              : true
        "
        required
        @valid="forms.vset.profile.newPassConfirm"
      />
    </div>
    <x-button
      id="save"
      type="large"
      style="margin-top: auto"
      :disabled="!forms.vget.profile.form"
      :is-loading="isLoading"
      @click="save"
    >
      {{ $t('form.save') }}
    </x-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'

export default {
  name: 'EditProfilePassword',
  components: { AppHeader },
  data() {
    return {
      forms: { profile: { pass: '', newPass: '', newPassConfirm: '' } },
      isLoading: false,
    }
  },
  methods: {
    save() {
      this.isLoading = true
      this.$store
        .dispatch('UserModule/changePassword', {
          current_password: this.forms.profile.pass,
          password: this.forms.profile.newPass,
          password_confirmation: this.forms.profile.newPassConfirm,
        })
        .then(() => this.$router.back())
        .finally(() => (this.isLoading = false))
    },
  },
  computed: mapState('UserModule', ['user']),
}
</script>

<style scoped lang="scss">
.x-label-text {
  font-size: 0.5625rem;
  color: var(--color-grey);
}
</style>
